<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="2"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
                  cols="12"
                  md="4"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="block">
                 <span class="demonstration">商品效期</span>
                    <el-date-picker
                            v-model="monthSearch"
                            type="monthrange"
                            visible="true"
                            start-placeholder="开始月份"
                            end-placeholder="结束月份">
                    </el-date-picker>
            </div>
          </b-col>
          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
            </div>
          </b-col>
        </b-row>

        <b-button
            variant="outline-primary"
            type="reset"
            @click="selectAllRows"
            class="ml-1 mt-1"
        >
          <span class="align-right">全选</span>
        </b-button>

        <b-button
            variant="outline-primary"
            type="reset"
            @click="clearSelected"
            class="ml-1 mt-1"
        >
          <span class="align-right">取消全选</span>
        </b-button>
      </div>


      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              hover
              small
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              v-model="table"
              selectable
              selected-variant="success"
              select-mode="multi"
              @row-selected="onRowEditSelected"
      >
        <template #cell(product_id)="data">
          #{{ data.item.product_id }}
        </template>


        <template #cell(stockStr)="data">
          {{data.item.ext.stockStr}}
        </template>


        <template #cell(name)="data">
          {{data.item.ext.name}}
        </template>


        <template #cell(code)="data">
          {{data.item.ext.code}}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

      </div>
    </b-card>
  </div>
</template>

<script>

import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions } from '@core/utils/filter'
import productUseList from '@/views/apps/allotoutbound/product-allotoutbound/productUseSelect'
import allotoutbounditemproductStore from "@/views/apps/allotoutbounditemproduct/allotoutbounditemproductStore";

import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,BModal,
    BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox,BIcon, BootstrapVueIcons,
    VBTooltip,
  } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ElDatePicker from "element-ui/packages/date-picker";
import XyCheckbox from "@/views/components/xy/XyCheckbox";
export default {
  components: {
    XyCheckbox,
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ElDatePicker
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      productId: 0,
      dataInfo: [],
      table: [],
    }
  },

  methods: {
    getChecked: function () {
      return this.checked
    },
    selectAllRows() {
      this.$refs.refListTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refListTable.clearSelected()
    },
    onRowEditSelected(items) {
      this.checked = items
    },
  },
  props: {
    warehouseId: {
      type: Number,
      default: 0,
    },
    inWarehouseId: {
      type: Number,
      default: 0,
    },
    outbound_id :{
      type : Number,
      default : 0
    },
    outbound_no :{
      type : String,
      default : ""
    },
    readonly:{
      type: Number,
      default:0,
    },
    purchaseTeamUserId:{
      type: Number,
      default: 0,
    },
    purchaseTeamIds:{
    }
  },
  setup(props) {
    // Register module

    if (!store.hasModule('allotoutbounditemproduct')) store.registerModule('allotoutbounditemproduct', allotoutbounditemproductStore)
    // if (!store.hasModule('stock')) store.registerModule('stock', stockStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutbounditemproduct')) store.unregisterModule('allotoutbounditemproduct')
      // if (store.hasModule('stock')) store.unregisterModule('stock')
    })


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      monthSearch,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveProductTypeCode,
      resolveStateCode,
    } = productUseList({
      warehouseId:props.warehouseId,
      purchaseTeamUserId:props.purchaseTeamUserId,
      purchaseTeamIds:props.purchaseTeamIds,
      outbound_id:props.outbound_id,
      inWarehouseId:props.inWarehouseId,
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      monthSearch,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // Filter
      avatarText,

      // UI
      resolveProductTypeCode,
      resolveStateCode,
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
