<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end" v-if="readonly===0">
              <b-button
                      variant="info"
                      @click="showselproduct()"
                      class="mr-1"
                      v-if="getCodeLabel('setting','start_inventory')!=='1'"
              >
                <span class="text-nowrap">选择商品</span>

              </b-button>

            </div>
          </b-col>
        </b-row>

      </div>


        <b-table
                ref="refListTable"
                class="position-relative"
                :items="searchList"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="orderBy"
                show-empty
                empty-text="未找到记录"
                :sort-desc.sync="isSortDirDesc"
                v-model="datalist"
        >

        <!-- Columns -->
        <!-- Columns -->


        <template #cell(is_receive)="data">
          {{getCodeLabel('yesno', data.item.is_receive)}}
        </template>

        <template #cell(allotbound_time)="data">
          {{toTime(data.item.allotbound_time)}}
        </template>

        <template #cell(receipt_time)="data">
          {{toTime(data.item.receipt_time)}}
        </template>

        <template #cell(allotbound_creator)="data">
          {{getCodeLabel('user', data.item.allotbound_creator)}}
        </template>

        <template #cell(receipt_creator)="data">
          {{getCodeLabel('user', data.item.receipt_creator)}}
        </template>

        <template #cell(num)="data">
          <span v-html="data.item.ext.mystock"></span>
        </template>

          <template #cell(stock_year)="data">
           {{data.item.stock_year}}-{{data.item.stock_month}}
          </template>


        <template #cell(qty)="data">

            <b-form-input
                    id="qty"
                    size="sm"
                    v-model="data.item.qty"
                    @change="checkQty(data.item)"
                    type="number"
                    :readonly="isNumber(readonly)===1"
            />

        </template>

          <template #cell(remark)="data">
            <b-form-input
                    id="remark"
                    size="sm"
                    v-model="data.item.remark"
                    @change="updateRemark(data.item)"
            />
          </template>

        <!-- Column: State -->


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link>
            <feather-icon @click="delOrderItem(data.item)"
                          icon="XSquareIcon"
                          size="16"
                          class="align-middle text-body"
            />
            <feather-icon @click="showStock(data.item)"
                          icon="FilterIcon"
                          size="16"
                          class="align-middle text-body"
            />
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
              id="stockModal"
              ok-only
              ok-title="确认"
              @ok="onSelectStock"
              cancel-title="取消"
              centered
              size="xl"
              :title="'请选择'"
      >
        <product-select
                ref="stockSelect" :inWarehouseId="inWarehouseId" :warehouseId="warehouseid" :purchaseTeamUserId="purchaseTeamUserId" :purchaseTeamStoreId="purchaseTeamStoreId" :purchaseTeamIds="purchaseTeamIds" :outbound_id="outbound_id">
        </product-select>
      </b-modal>

      <b-modal
          id="product-stock"
          ok-only
          ok-title="确认"
          @ok="onSelectSalesOrderStock"
          cancel-title="取消"
          centered
          size="xl"
          :title="'请选择'"
      >
        <stock-manage
            ref="productStockSelect"
            :warehouseid="warehouseidtemp"
            :inWarehouseId="inWarehouseId"
            :purchaseTeamIds="parseInt(purchaseTeamIds)"
            :productId="item.product_id"
            :qty="item.qty"
            :productName="item.name"
            :orderId="outbound_id"
            :stockYear="item.stock_year"
            :stockMonth="item.stock_month"
            :itemId="item.outbounditem_id"
            :type="1"
            :stockArray="productStockArray.filter(e=>e.item_id == item.outbounditem_id)"
        >
        </stock-manage>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  isNumber
} from '@core/utils/filter'
import allotoutbounditemproductUseList from '@/views/apps/allotoutbounditemproduct/allotoutbounditemproductUseList'
import allotoutbounditemproductStore from '@/views/apps/allotoutbounditemproduct/allotoutbounditemproductStore'
import ProductSelect from "@/views/apps/allotoutbound/product-allotoutbound/ProductSelect";
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";
import StockManage from "@/views/apps/stock/product-stock/StockManage";
export default {
  components: {
    StockManage,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ProductSelect,
  },
  data(){
    return{
      datalist:[],
      warehouseid:0,
      item:{},
      productStockArray:[]
    }
  },
  props:{
    outbound_id :{
      type : Number,
      default : 0
    },
    warehouseidtemp:{
      type: Number,
      default:0
    },
    inWarehouseId: {
      type: Number,
      default: 0,
    },
    outboundNo:{
      type: String,
      default:0
    },
    readonly:{
      type: Number,
      default:0,
    },
    purchaseTeamUserId:{
      type: Number,
      default: 0,
    },
    purchaseTeamStoreId:{
      type: Number,
      default: 0,
    },
    purchaseTeamIds:{
    }
  },
  methods: {

  },
  setup(props) {

    const toast = useToast()

    // Register module
    if (!store.hasModule('allotoutbounditemproduct')) store.registerModule('allotoutbounditemproduct', allotoutbounditemproductStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutbounditemproduct')) store.unregisterModule('allotoutbounditemproduct')
    })

    const showselproduct = function(){
      if(props.warehouseidtemp == 0){
        toast.error("请选择调出仓库")
        return false
      }
      if(props.inWarehouseId == 0){
        toast.error("请选择调入仓库")
        return false
      }
      if (isEmpty(props.purchaseTeamIds)){
        toast.error("请选择销售权")
        return false
      }
      this.$bvModal.show('stockModal')
      this.warehouseid = props.warehouseidtemp
    }



    const onSelectStock = function (){
      let productData = this.$refs.stockSelect.getChecked()
      if(productData.length > 0){
        let productIds = "";
        let months = "";
        for(let i = 0 ; i<productData.length ; i ++){
          let itemData = productData[i];
          if(isEmpty(productIds)){
            productIds = itemData.product_id
            months = itemData.year_and_month
          }else{
            productIds += ","+itemData.product_id
            months += ","+itemData.year_and_month
          }
        }
        //保存添加的明细
        axios.post('api/allotoutbounditemproduct/addItemProduct', {outboundId: props.outbound_id, productIds:productIds,months:months}).then(res => {
          if(res.data.code == 0){
             refetchData()
          }else{
            toast.error(res.data.data)
          }
        })
      }
    }

    const checkQty = function(item){
      //查询可用库存
      axios.post('api/allotoutbound/getProductQtyFromOneWarehouse', {year:item.stock_year,month:item.stock_month,orderId:props.outbound_id,productId: item.product_id,inWarehouseId: props.inWarehouseId, warehouse_id: props.warehouseidtemp,purchaseTeamUserId:props.purchaseTeamUserId,purchaseTeamStoreId:props.purchaseTeamStoreId,purchaseTeamIds:props.purchaseTeamIds}).then(res => {
        let  canUseQty =  0
        if(res.data.code === 0){
          canUseQty = res.data.data.ext.canUseQty
          if (isNumber(item.qty) > 0) {
            const cRegExp = /^[0-9]\d*$/
            if (!cRegExp.test(item.qty)) {
              toast.error('调拨数量必须是正整数!')
              item.qty = 0
            } else if(item.ext.canUseQty!=canUseQty){
              toast.error("其它页面已提交仓库!")
              refetchData()
            }
            else if(item.qty > canUseQty) {
              toast.error("调拨数量不可大于可用库存!")
              refetchData()
            } else {
              axios.post('api/allotoutbounditemproduct/updateOrderItem', {itemid: item.outbounditem_id, qty: item.qty}).then(res => {
                if(res.data.code === 0){
                  toast.success("商品"+item.name+"保存成功")
                  refetchData()
                }else{
                  toast.error("商品"+item.name+"保存失败 "+res.data.data)
                  refetchData()
                }
              })
            }
          } else {
            toast.error('调拨数量填写错误！')
            item.qty = 0
          }
        }
      })
    }

    const updateRemark = function (item) {
      axios.post('api/allotoutbounditemproduct/updateOrderItemRemark', {itemid: item.outbounditem_id, remark: item.remark}).then(res => {
        if(res.data.code === 0){
          toast.success("商品"+item.name+"保存成功")
          refetchData()
        }else{
          toast.error("商品"+item.name+"保存失败 "+res.data.data)
          refetchData()
        }
      })
    }

    const delOrderItem = function(item) {
      if(confirm("确认删除吗？")){
        axios.post('api/allotoutbounditemproduct/state', {id: item.outbounditem_id,state:0}).then(res => {
          if (res.data.code == 0) {
            toast.success("删除成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const showStock = function (params) {
      this.item = params
      this.$bvModal.show('product-stock')
    }

    const onSelectSalesOrderStock = function (params) {
      let checked = this.$refs.productStockSelect.getChecked();
      const array = []
      let sumQty = 0
      for (let i = 0; i < checked.length; i++) {
        array.push({
          stock_id:checked[i].stock_id,
          product_id:checked[i].product_id,
          qty:checked[i].ext.useQty,
        })
        sumQty+=parseInt(checked[i].ext.useQty);
      }
      if (this.item.qty!=sumQty){
        toast.error("数量不一致,无法保存批次!")
        return;
      }
      for (let i = 0; i < this.productStockArray.length; i++) {
        if (this.productStockArray[i].item_id === this.item.outbounditem_id){
          this.productStockArray.splice(i,1)
        }
      }
      this.productStockArray.push({
        item_id:this.item.outbounditem_id,
        array:array
      })
      // this.item.ext.productStockArray = array
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = allotoutbounditemproductUseList(
            {
              objectAll:props,
            }
    )

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
     toTime,
     toDate,
      onSelectStock,
      //onSelectAllotoutboundStock,
      checkQty,
      updateRemark,
      delOrderItem,
      showselproduct,
      isNumber,
      showStock,
      onSelectSalesOrderStock

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
