<template>
  <b-card
      class="allotoutbound-edit-wrapper"
  >
    <!-- form -->
    <b-form id="allotoutboundForm" class="edit-form mt-2">

      <b-card header="基本信息">
        <b-row>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="编号"
                label-for="outbound_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="outbound_no"
                  size="sm"
                  v-model="allotoutbound.outbound_no"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨类型"
                label-for="allot_type"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="allot_type"
                  :options="getCodeOptions('allot_type')"
                  :clearable="false"
                  @input="changeSelect('allot_type',$event)"
                  v-model="allot_type"
                  class="select-size-sm"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="责任人"
                label-for="duty_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="duty_name"
                  size="sm"
                  v-model="allotoutbound.duty_name"
                  @click="showPeopleModal()"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨日期"
                label-for="allot_time"
                label-size="sm"
                class="mb-1 required"
            >
              <flat-pickr
                  v-model="allotTime"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>


      <b-card header="调出仓库">
        <b-row>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库"
                label-for="fromwarehouse_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="fromwarehouse_name"
                  size="sm"
                  v-model="allotoutbound.fromwarehouse_name"
                  @click="showFromWHModal()"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系人"
                label-for="from_contact"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_contact"
                  size="sm"
                  v-model="allotoutbound.from_contact"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系电话"
                label-for="from_mobile"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_mobile"
                  size="sm"
                  v-model="allotoutbound.from_mobile"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库地址"
                label-for="from_location"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_location"
                  size="sm"
                  v-model="allotoutbound.from_location"
                  readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card header="调入仓库">
        <b-row>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库"
                label-for="towarehouse_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="towarehouse_name"
                  size="sm"
                  v-model="allotoutbound.towarehouse_name"
                  @click="showToWHModal()"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系人"
                label-for="to_contact"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_contact"
                  size="sm"
                  v-model="allotoutbound.to_contact"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系电话"
                label-for="to_mobile"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_mobile"
                  size="sm"
                  v-model="allotoutbound.to_mobile"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库地址"
                label-for="to_location"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_location"
                  size="sm"
                  v-model="allotoutbound.to_location"
                  readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card header="其他">
        <b-row>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload v-if="allotoutbound.loaded" :theme="'files'"
                                 :attachment_id="'attachments'"
                                 :id="allotoutbound.attachments"
                                 :object_type="'allot_outbound'"
                                 :object_id="allotoutbound.outbound_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="备注"
                label-for="memo"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="memo"
                  size="sm"
                  v-model="allotoutbound.memo"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card header="销售权">
        <b-row>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="销售权"
                label-for="purchase_team_id"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="purchase_team_id"
                  :options="getCodeOptions('purchase_team')"
                  @input="changeSelect('purchase_team_id',$event)"
                  v-model="purchase_team_id"
                  class="select-size-sm"
                  :disabled="readonly!==0"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-col md="12">
        <div class="inner-card-title">明细列表</div>
      </b-col>

      <!--   <div v-if="isNumber(isExitItemProduct)===0">
                  <allot-outbound-item-list ref="itemList" :outbound_id="allotoutbound.outbound_id" :outboundNo="allotoutbound.outbound_no" :readonly="readonly" :warehouseidtemp="allotoutbound.fromwarehouse_id" v-if="allotoutbound.outbound_id > 0 && isshowitemlist">
                   </allot-outbound-item-list>
         </div>-->

      <div>
        <allot-outbound-item-product-list ref="itemList" :outbound_id="allotoutbound.outbound_id"
                                          :outboundNo="allotoutbound.outbound_no"
                                          :purchaseTeamUserId="allotoutbound.creator" :readonly="readonly"
                                          :warehouseidtemp="allotoutbound.fromwarehouse_id"
                                          :inWarehouseId="allotoutbound.towarehouse_id"
                                          :purchaseTeamIds="allotoutbound.purchase_team_id"
                                          v-if="allotoutbound.outbound_id > 0 && isshowitemlist "

        >
        </allot-outbound-item-product-list>
      </div>

      <!--分页-->
      <b-col
          cols="12"
          class="mt-50"
      >
        <b-button
            variant="primary"
            class="mr-1"
            @click="save"
            v-if="readonly===0"
        >
          保存
        </b-button>

        <b-button
            variant="primary"
            class="mr-1"
            @click="submitWarehouse"
            v-if="readonly===0"
        >
          提交仓库
        </b-button>

        <b-button
            variant="outline-secondary"
            @click="cancel"
        >
          取消
        </b-button>
      </b-col>
    </b-form>
    <!--/ form -->


    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectPeople"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'"
        ref="peopleModal"
    >
      <user-select
          ref="peopleSelect" v-on:table="fromChildren($event,'user')"
      >
      </user-select>
    </b-modal>

    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectWHFromPeople"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'"
        ref="fromWHModal"
    >
      <WareHouse-Select
          ref="whSelect" v-on:table="fromChildren($event,'from')"
      >
      </WareHouse-Select>
    </b-modal>

    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectWHToPeople"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'"
        ref="toWHModal"
    >
      <WareHouse-Select
          ref="whSelect" v-on:table="fromChildren($event,'to')"
      >
      </WareHouse-Select>
    </b-modal>

    <div v-show="loadingModal">
      <xy-loading :placeholder="loadingPlaceholder"></xy-loading>
    </div>


  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BCalendar,
  BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import allotoutboundStore from './allotoutboundStore'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'

import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second, isNumber} from '@core/utils/filter'
import AllotOutboundItemList from '@/views/apps/allotoutbounditem/AllotOutboundItemList'
import AllotOutboundItemProductList from '@/views/apps/allotoutbounditemproduct/AllotOutboundItemProductList'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import flatPickr from 'vue-flatpickr-component'
import {getUserData} from "@/auth/utils";
import allotoutbounditemproductStore from "@/views/apps/allotoutbounditemproduct/allotoutbounditemproductStore";
import axios from "@/libs/axios";
import XyLoading from "@/views/components/xy/XyLoading";


import MessageBox from 'element-ui/packages/message-box'
import Message from 'element-ui/packages/message'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCalendar,
    BFormDatepicker,
    vSelect,
    useToast,


    UserSelect,
    WareHouseSelect,
    AllotOutboundItemList,
    AttachmentUpload,
    flatPickr,
    AllotOutboundItemProductList,
    XyLoading

  },
  data() {
    return {
      id: 0,
      allotoutbound: {},
      allot_type: "",
      isshowitemlist: true,
      readonly: 0,
      user: {},
      //isExitItemProduct:0,
      loadingModal: false,
      loadingPlaceholder: "单据正在生成,请勿重复操作!",
      purchase_team_id:"",
      isExitZeroCostStockMsg:'',
      allotTime:new Date(),
    }
  },
  props: {},
  methods: {

    showPeopleModal() {
      this.$refs['peopleModal'].show()
    },
    onSelectPeople() {
      let returnData = {}
      if (this.$refs.peopleSelect.getSelected().length > 0) {
        returnData = this.$refs.peopleSelect.getSelected()[0]
        this.allotoutbound.duty_id = returnData.user_id
        this.allotoutbound.duty_name = returnData.full_name
        this.$forceUpdate()
      }
    },
    showFromWHModal() {
      this.$refs['fromWHModal'].show()
    },
    onSelectWHFromPeople() {
      let returnData = {}
      if (this.$refs.whSelect.getSelected().length > 0) {
        returnData = this.$refs.whSelect.getSelected()[0]
        //判斷是否已添加产品
        var myitem = this.$refs.itemList.datalist
        if (myitem.length > 0) {
          let issame = true;
          for (let i = 0; i < myitem.length; i++) {
            if (this.allotoutbound.fromwarehouse_id != myitem[i].warehouseid) {
              issame = false
            }
          }
          if (!issame) {
            alert("已添加过产品，不可修改调出仓库")
            return false
          }
        }

        this.allotoutbound.fromwarehouse_id = returnData.id
        this.allotoutbound.fromwarehouse_name = returnData.warehouse_name
        this.allotoutbound.from_contact = returnData.contact
        this.allotoutbound.from_mobile = returnData.mobile
        this.allotoutbound.from_location = returnData.location
        this.$forceUpdate()
      }

      this.isshowitemlist = false
      this.isshowitemlist = true
    },
    showToWHModal() {
      this.$refs['toWHModal'].show()
    },
    onSelectWHToPeople() {
      let returnData = {}
      if (this.$refs.whSelect.getSelected().length > 0) {
        returnData = this.$refs.whSelect.getSelected()[0]

        //判斷是否已添加产品
        var myitem = this.$refs.itemList.datalist
        if (myitem.length > 0) {
          let issame = true;
          for (let i = 0; i < myitem.length; i++) {
            if (this.allotoutbound.fromwarehouse_id != myitem[i].warehouseid) {
              issame = false
            }
          }
          if (!issame) {
            if(confirm("是否确认更改调入仓库？")) {
              if (this.allotoutbound.status !== 0) {
                alert("已添加过产品，不可修改调入仓库")
                return false
              }
            }
          }
        }

        this.allotoutbound.towarehouse_id = returnData.id
        this.allotoutbound.towarehouse_name = returnData.warehouse_name
        this.allotoutbound.to_contact = returnData.contact
        this.allotoutbound.to_mobile = returnData.mobile
        this.allotoutbound.to_location = returnData.location
        this.$forceUpdate()
      }
    },
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('allotoutbound')) store.registerModule('allotoutbound', allotoutboundStore)
    if (!store.hasModule('allotoutbounditemproduct')) store.registerModule('allotoutbounditemproduct', allotoutbounditemproductStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutbound')) store.unregisterModule('allotoutbound')
      if (store.hasModule('allotoutbounditemproduct')) store.unregisterModule('allotoutbounditemproduct')
    })

    const edit = function () {
      store.dispatch('allotoutbound/edit', {id: this.id}).then(res => {
        if (res.data.code == 1) {
          toast.error(res.data.data)
          this.$router.push({name: 'apps-allotoutbound-list'})
        }
        this.allotoutbound = res.data.data
        this.allotTime = toDate(this.allotoutbound.allot_time)
        this.allot_type = getCode('allot_type', this.allotoutbound.allot_type)
        this.purchase_team_id = getCode('purchase_team', this.allotoutbound.purchase_team_id)
        this.allotoutbound.duty_name = this.user.full_name
        this.allotoutbound.duty_id = this.user.user_id
        // if(isNumber(this.isExitItemProduct)===0){
        //   this.isExitItemProduct = isNumber(this.allotoutbound.ext.isExitItemProduct)
        // }
        // console.log(this.isExitItemProduct)
      })
    }

    const view = function () {
      store.dispatch('allotoutbound/view', {id: this.id}).then(res => {
        this.allotoutbound = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      var myitem = this.$refs.itemList.datalist
      var productStockArray = this.$refs.itemList.productStockArray

      if (this.allotoutbound.allot_type == 0 || this.allotoutbound.allot_type == undefined) {
        toast.error("请选择 调拨类型")
        return false
      }
      if (this.allotoutbound.duty_id == 0 || this.allotoutbound.duty_id == undefined) {
        toast.error("请选择 责任人")
        return false
      }
      if (this.allotoutbound.fromwarehouse_id == 0 || this.allotoutbound.fromwarehouse_id == undefined) {
        toast.error("请选择 调出仓库")
        return false
      }
      if (this.allotoutbound.towarehouse_id == 0 || this.allotoutbound.towarehouse_id == undefined) {
        toast.error("请选择 调入仓库")
        return false
      }

      if (this.allotoutbound.fromwarehouse_id == this.allotoutbound.towarehouse_id) {
        toast.error("调出仓库和调入仓库不能相同！")
        return false
      }
      if (this.allotTime == 0 || this.allotTime == undefined) {
        toast.error("请填写 调拨日期")
        return false
      }

      const itemIds = []
      const itemStockIds = []
      for (let i = 0; i < myitem.length; i++) {
        if (myitem[i].qty === "" || myitem[i].qty === 0) {
          toast.error("商品" + myitem[i].name + "未输入数量！")
          return false
        }
        itemIds.push(myitem[i].outbounditem_id)
        // itemStockIds.push(myitem[i].ext.productStockArray)
        for (let j = 0; j < productStockArray.length; j++) {
              if (productStockArray[j].item_id ==myitem[i].outbounditem_id){
                itemStockIds.push(productStockArray[j].array)
              }
        }
        if (itemStockIds[i] == null){
          itemStockIds[i] = []
        }
      }
      this.allotoutbound.allot_time = second(this.allotTime)
      this.allotoutbound.itemIds = JSON.stringify(itemIds)
      this.allotoutbound.itemStockIds = JSON.stringify(itemStockIds)
      this.$forceUpdate()
      var promise = Promise.resolve()
      promise = promise.then(() => {
        this.id = this.allotoutbound.outbound_id
        this.isExitZeroCostStockMsg = ''
        return isExitZeroCostStock(this.allotoutbound, this)
      })
      promise.then(() => {
        store.dispatch('allotoutbound/save', this.allotoutbound).then(res => {
          if (res.data.code === 0) {
            toast.success('页面数据保存成功!')
          } else {
            toast.error(res.data.data)
          }
        })
      })

    }

    const submitWarehouse = function () {
      var myitem = this.$refs.itemList.datalist
      var productStockArray = this.$refs.itemList.productStockArray

      if (this.allotoutbound.allot_type == 0 || this.allotoutbound.allot_type == undefined) {
        toast.error("请选择 调拨类型")
        return false
      }
      if (this.allotoutbound.duty_id == 0 || this.allotoutbound.duty_id == undefined) {
        toast.error("请选择 责任人")
        return false
      }
      if (this.allotoutbound.fromwarehouse_id == 0 || this.allotoutbound.fromwarehouse_id == undefined) {
        toast.error("请选择 调出仓库")
        return false
      }
      if (this.allotoutbound.towarehouse_id == 0 || this.allotoutbound.towarehouse_id == undefined) {
        toast.error("请选择 调入仓库")
        return false
      }

      if (this.allotoutbound.fromwarehouse_id == this.allotoutbound.towarehouse_id) {
        toast.error("调出仓库和调入仓库不能相同！")
        return false
      }
      if (this.allotTime == 0 || this.allotTime == undefined) {
        toast.error("请填写 调拨日期")
        return false
      }

      const itemIds = []
      const itemStockIds = []
      for (let i = 0; i < myitem.length; i++) {
        if (myitem[i].qty === "" || myitem[i].qty === 0) {
          toast.error("商品" + myitem[i].name + "未输入数量！")
          return false
        }
        itemIds.push(myitem[i].outbounditem_id)
        // itemStockIds.push(myitem[i].ext.productStockArray)
        for (let j = 0; j < productStockArray.length; j++) {
          if (productStockArray[j].item_id ==myitem[i].outbounditem_id){
            itemStockIds.push(productStockArray[j].array)
          }
        }
        if (itemStockIds[i] == null){
          itemStockIds[i] = []
        }
      }

      this.allotoutbound.allot_time = second(this.allotTime)
      this.allotoutbound.itemIds = JSON.stringify(itemIds)
      this.allotoutbound.itemStockIds = JSON.stringify(itemStockIds)
      this.$forceUpdate()
      var promise = Promise.resolve()
      promise = promise.then(() => {
        this.id = this.allotoutbound.outbound_id
        this.isExitZeroCostStockMsg = ''
        return isExitZeroCostStock(this.allotoutbound, this)
       })
      promise.then(() => {
          this.loadingModal = true
          store.dispatch('allotoutbound/save', this.allotoutbound)
                  .then(res => {
                    this.loadingModal = false
                    if (res.data.code === 0) {
                      toast.success('页面数据保存成功!')
                      store.dispatch('allotoutbound/submitWareHouse', { id: res.data.data.outbound_id })
                              .then(res => {
                                this.submitWareHouseLater = true
                                if (res.data.code == 0) {
                                  toast.success("操作成功")
                                  refetchData()
                                } else {
                                  toast.error(res.data.data)
                                }
                              })
                      this.$router.push({ name: 'apps-allotoutbound-list' });
                    } else {
                      toast.error(res.data.data)
                    }
                  })

      })

    }


    const onUploaded = function (id, attachment, result) {
      this.allotoutbound[id] = result
    }

    const fromChildren = function (data, modal) {
      if (modal == 'user') {
        this.allotoutbound.duty_id = data.user_id
        this.allotoutbound.duty_name = data.full_name
        this.$forceUpdate()
      }
      if (modal == "from") {
        //判斷是否已添加产品
        var myitem = this.$refs.itemList.datalist

        if (myitem.length > 0) {
          let issame = true;
          for (let i = 0; i < myitem.length; i++) {
            if (this.allotoutbound.fromwarehouse_id != myitem[i].warehouseid) {
              issame = false
            }
          }
          if (!issame) {
            alert("已添加过产品，不可修改调出仓库")
            return false
          }
        }

        this.allotoutbound.fromwarehouse_id = data.id
        this.allotoutbound.fromwarehouse_name = data.warehouse_name
        this.allotoutbound.from_contact = data.contact
        this.allotoutbound.from_mobile = data.mobile
        this.allotoutbound.from_location = data.location
        this.$forceUpdate()

        this.isshowitemlist = false
        this.isshowitemlist = true
      }
      if (modal == "to") {
        this.allotoutbound.towarehouse_id = data.id
        this.allotoutbound.towarehouse_name = data.warehouse_name
        this.allotoutbound.to_contact = data.contact
        this.allotoutbound.to_mobile = data.mobile
        this.allotoutbound.to_location = data.location
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    const changeSelect = function (key, event) {
      this.allotoutbound[key] = event == null ? undefined : event.value
      if (key==='purchase_team_id'){
        axios.post('api/allotoutbounditemproduct/deleteAllItemProductByOutboundId', {id:this.allotoutbound.outbound_id}).then(res => {
          this.$refs.itemList.refetchData()
        })
      }
    }

    const isExitZeroCostStock = function(id,_this){
              return new Promise((resolve, reject) => {
                store.dispatch('allotoutbound/isExitZeroCostStock', _this.allotoutbound)
                        .then(res => {
                          if (res.data.code === 0) {
                            let list = res.data.data
                            if (list.length > 0) {
                              let message = '';
                              for (let j = 0; j < list.length; j++) {
                                message += `
                               <h5>[${list[j].name},${list[j].stock_year}-${list[j].stock_month}]</h5><hr>
                           `
                              }
                              message += `
                           <strong>存在0成本调拨,是否继续。</strong><hr>
                         `
                              MessageBox.confirm(message, {
                                        title: '提示  ',
                                        size: 'sm',
                                        okVariant: 'primary',
                                        okTitle: '确认',
                                        cancelTitle: '取消',
                                        cancelVariant: 'outline-secondary',
                                        hideHeaderClose: true,
                                        centered: true,
                                        dangerouslyUseHTMLString:true,
                                      })
                                      .then(value => {
                                        resolve()
                                      })
                            }else{
                              resolve()
                            }
                          }
                        })
              })
    }

    const confirmData = function () {

    }


    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      second,
      onUploaded,
      fromChildren,
      isNumber,
      submitWarehouse,
      changeSelect,
      isExitZeroCostStock,
      confirmData

    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    this.id = this.$route.query.id || 0;
    this.readonly = this.$route.params.readonly || null;
    if (this.$route.params.readonly === 0 || this.id === 0) {
      this.readonly = 0
    }
    //this.isExitItemProduct = isNumber(this.$route.params.isExitItemProduct);
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
